<template>
    <div class="template">
        <router-view/>
    </div>
</template>

<script>
import Templates from "@/api/v2/endpoints/Templates";
import fileExtension from "@/mixin/fileExtension";

export default {
    name: 'Template',
    mixins: [fileExtension],
    data() {
        return {
            templateId: null,
        };
    },
    created() {
        if (this.$route.name === 'Template') {
            this.templateId = this.$route.params.id;
            this.setTemplateEditForm();
        }
    },
    methods: {
        setTemplateEditForm() {
            this.loadTemplate().then(template => {
                if (template.upload && this.isCompatibleWithTheEditor(template.upload.extension)) {
                    return this.redirectToForm('TemplateEditor');
                }

                return this.redirectToForm('TemplateEdit');
            });
        },
        loadTemplate() {
            return Templates
                .show(this.templateId, {template_data: 'id', with_upload: 'name'})
                .then(response => response.data);
        },
        redirectToForm(routeName) {
            this.$router.push({
                name: routeName,
                params: {
                    id: this.templateId
                }
            });
        }
    }
}
</script>
